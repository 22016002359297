import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Popover, Stack, Tooltip, Typography } from '@mui/material';
import styles from './styles/IndicationChangeTimelineView.styles';
import ApprovalDateIcon from '../../../assets/svgs/CardsPage/ApprovalDateIcon';
import { IndicationChangeTimelineViewProps } from './type';
import IndicationBadge from './IndicationBadge';
import getSubmissionName from '../../../components/Timeline/TimelineDocuments/getSubmissionName';
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import IndicationPopover from './IndicationPopover';
import IndicationChangeDrawer from './IndicationChangeDrawer';
import getIndicatioDrawerData from './utils';

const IndicationChangeTimelineView = ({
  indicationList,
  dataByYear,
  source,
  setScrollRefElement
}: IndicationChangeTimelineViewProps) => {
  const { regulatoryState } = useContext(RegulatoryInformationStore);
  const centerRef = useRef<HTMLDivElement>(null);
  const viewRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 });
  const [isExpandedCardOpen, setIsExpandedCardOpen] = useState(false);
  const [activeBadge, setActiveBadge] = useState('');
  const [expandedPayload, setExpandedPayload] = useState<any>({});
  const [openIndicationDrawer, setOpenIndicationDrawer] = useState<boolean>(false);

  useEffect(() => {
    setScrollRefElement(scrollRef);
  }, [scrollRef]);

  const handleClose = () => {
    setIsExpandedCardOpen(false);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
    setLastPosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!isDragging) return;

    if (scrollRef.current) {
      const { scrollTop, scrollLeft } = scrollRef.current;
      scrollRef.current.scrollTop = scrollTop - (event.clientY - lastPosition.y);
      scrollRef.current.scrollLeft = scrollLeft - (event.clientX - lastPosition.x);
    }

    setLastPosition({ x: event.clientX, y: event.clientY });
  };

  const subEntities = useMemo(() => {
    return regulatoryState.submissionDocuments;
  }, [regulatoryState]);

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const getIndicationData = useMemo(() => {
    return getIndicatioDrawerData(expandedPayload);
  }, [expandedPayload]);

  const sortByYearDescending = (a: [string, any], b: [string, any]) => {
    const yearA = parseInt(a[0], 10);
    const yearB = parseInt(b[0], 10);
    return yearB - yearA;
  };

  return (
    <Stack
      component='div'
      direction='row'
      width='auto'
      height='100%'
      ref={scrollRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      sx={{
        ...styles.horizontalTimelineStyle,
        '&>div': {
          transform: null,
          transformOrigin: 'left top',
          transition: 'transform 0.3s ease-in-out',
          minWidth: '180px'
        }
      }}>
      <Stack sx={styles.timeLineCards} ref={viewRef} height='100%'>
        {indicationList?.map((appl: any) => {
          return (
            <Stack key={appl} sx={styles.CardContainer}>
              <Tooltip key={appl} title={appl}>
                <Typography key={appl} noWrap sx={styles.applText}>
                  {appl}
                </Typography>
              </Tooltip>
            </Stack>
          );
        })}
      </Stack>
      <Stack flexDirection='row' height='100%'>
        {Object.entries(dataByYear)
          .sort(sortByYearDescending)
          .map(([year, data]: any) => {
            return (
              <Stack
                direction='column'
                alignItems='center'
                key={year}
                minWidth='115px !important'
                id={`year-${year}`}
                ref={centerRef}>
                <Stack
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                  width='100%'
                  height={24}
                  sx={{
                    ...styles.yearBox,
                    position: 'sticky'
                  }}>
                  <Box>
                    <Typography
                      sx={{ fontWeight: 600, color: 'gray.600', fontSize: '14px' }}
                      variant='caption'>
                      {year === '1900' ? 'NA' : year}
                    </Typography>
                  </Box>
                </Stack>
                <Stack display='flex' justifyContent='center' flexDirection='column' width='100%'>
                  {indicationList.map((item: string) => {
                    if (item in data.indication_data && item in data.approval_date) {
                      return (
                        <IndicationBadge
                          key={item}
                          data={data}
                          indication={item}
                          activeBadge={activeBadge}
                          isExpandedCardOpen={isExpandedCardOpen}
                          setActiveBadge={setActiveBadge}
                          setExpandedPayload={setExpandedPayload}
                          setIsExpandedCardOpen={setIsExpandedCardOpen}
                          setAnchorEl={setAnchorEl}
                          showApprovalDate
                        />
                      );
                    }
                    if (item in data.indication_data) {
                      return (
                        <IndicationBadge
                          key={item}
                          data={data}
                          indication={item}
                          activeBadge={activeBadge}
                          isExpandedCardOpen={isExpandedCardOpen}
                          setActiveBadge={setActiveBadge}
                          setExpandedPayload={setExpandedPayload}
                          setIsExpandedCardOpen={setIsExpandedCardOpen}
                          setAnchorEl={setAnchorEl}
                        />
                      );
                    }
                    if (item in data.approval_date) {
                      return (
                        <Box className='plus' sx={styles.plusStyle} key={item}>
                          <Box sx={styles.badgeContainer}>
                            <ApprovalDateIcon />
                          </Box>
                        </Box>
                      );
                    }
                    return (
                      <Box key={item} className='eplus' sx={styles.plusStyle}>
                        <Box
                          sx={{
                            zIndex: 1,
                            position: 'relative'
                          }}
                        />
                      </Box>
                    );
                  })}
                </Stack>
              </Stack>
            );
          })}
      </Stack>
      <Popover
        open={isExpandedCardOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        slotProps={{
          paper: {
            sx: styles.popoverContainer
          }
        }}>
        <IndicationPopover
          selectedEntityId={getSubmissionName(expandedPayload, 'us')}
          subEntities={subEntities}
          expandedPayload={expandedPayload}
          handleClose={handleClose}
          setOpenIndicationDrawer={setOpenIndicationDrawer}
        />
      </Popover>
      {openIndicationDrawer && (
        <IndicationChangeDrawer
          selectedEntityId={getSubmissionName(expandedPayload, source as string)}
          subEntities={subEntities}
          indicationData={getIndicationData}
          onClose={() => {
            setOpenIndicationDrawer(false);
          }}
        />
      )}
    </Stack>
  );
};

export default React.memo(IndicationChangeTimelineView);
