import React, { useContext, useEffect, useMemo, useState } from 'react';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Divider } from '@mui/material';
import CustomTimelinePropsInterface from '../../../interfaces/CustomTimelinePropsInterface';

import styles from './styles/IndicationChange.styles';
import IndicationChangeDrawer from './IndicationChangeDrawer';
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import getSubmissionName from '../../../components/Timeline/TimelineDocuments/getSubmissionName';
import getIndicatioDrawerData, { formatText } from './utils';

const isOverflown = (element: Element): boolean => {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
};

const IndicationChange: React.FC<CustomTimelinePropsInterface> = ({
  column,
  dataItem,
  source
}: CustomTimelinePropsInterface) => {
  const [concatValue, setConcatValue] = useState<string>('');
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);
  const [openIndicationDrawer, setOpenIndicationDrawer] = useState<boolean>(false);
  const { regulatoryState } = useContext(RegulatoryInformationStore);
  useEffect(() => {
    if (column !== undefined) {
      if (Array.isArray(dataItem[column.name])) {
        const concatString = dataItem[column.name]?.join(', ') || '';
        setConcatValue(concatString);
        setIsDisable(concatValue === '');
      } else {
        setConcatValue(dataItem[column.name]);
        setIsDisable(!dataItem[column.name]);
      }
    }
  }, [dataItem, column]);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = cellValue.current ? isOverflown(cellValue.current) : false;
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  const subEntities = useMemo(() => {
    return regulatoryState.submissionDocuments;
  }, [regulatoryState]);

  const getIndicationData = useMemo(() => {
    return getIndicatioDrawerData(dataItem);
  }, [dataItem]);

  return (
    <Stack
      height='100%'
      display='flex'
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'
      ref={cellDiv}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <Box width='auto' sx={styles.container}>
        <Typography
          ref={cellValue}
          maxHeight={110}
          overflow='hidden'
          textOverflow='ellipsis'
          variant='subtitle1'
          sx={styles.text}>
          {' '}
          {!isDisable && (
            <Stack display='flex' flexDirection='column'>
              <Box
                gap={1}
                display='flex'
                flexDirection='row'
                alignItems='center'
                justifyContent='space-between'
                height={25}>
                <Stack display='flex' flexDirection='row' alignItems='center'>
                  Compared to <Box sx={styles.submissionText}>{dataItem?.submission_compared}</Box>
                </Stack>
                <Typography sx={styles.moreButton}>View All</Typography>
              </Box>
              <Divider sx={{ color: 'gray.300' }} />
            </Stack>
          )}
          {concatValue ? concatValue.replace(/\*\*(.*?)\*\*/g, '$1') : '........'}
        </Typography>

        {showPopper && (
          <Popper
            open={showFullCell && anchorEl !== null}
            anchorEl={cellDiv.current}
            placement='bottom-start'
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, -110]
                }
              }
            ]}>
            <Paper elevation={1} sx={styles.paperContainer}>
              <Box
                gap={1}
                display='flex'
                flexDirection='row'
                alignItems='center'
                justifyContent='space-between'
                height={25}>
                <Stack
                  display='flex'
                  flexDirection='row'
                  alignItems='center'
                  sx={styles.subHeading}>
                  Compared to <Box sx={styles.submissionText}>{dataItem?.submission_compared}</Box>
                </Stack>
                <Tooltip title='View All Indications, Indications Change(s), New Disease(s) and Therapeutic Use details'>
                  <IconButton
                    disabled={isDisable}
                    onClick={() => {
                      setOpenIndicationDrawer(true);
                      handleMouseLeave();
                    }}
                    sx={{
                      p: 0.25
                    }}>
                    <Typography sx={styles.moreButton}>View All</Typography>
                  </IconButton>
                </Tooltip>
              </Box>
              <Divider sx={{ color: 'gray.300' }} />
              <Typography
                variant='body2'
                sx={{ fontSize: 12 }}
                dangerouslySetInnerHTML={{
                  // replace string under ** ** to bold eg:- **indication change** --> <strong>indication change</strong>
                  __html: formatText(concatValue.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>'))
                }}
              />
            </Paper>
          </Popper>
        )}
      </Box>
      {openIndicationDrawer && (
        <IndicationChangeDrawer
          selectedEntityId={getSubmissionName(dataItem, source)}
          subEntities={subEntities}
          indicationData={getIndicationData}
          onClose={() => {
            setOpenIndicationDrawer(false);
          }}
        />
      )}
    </Stack>
  );
};

export default React.memo(IndicationChange);
