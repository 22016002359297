import { regulatory360ApiWithFilters } from '../../../api/pages/Regulatory360';
import RESULT_VIEW_TYPES from '../../SearchResults/components/constants';

const getIndicationChangeData = async (source: string, applno: string, filters: any) => {
  try {
    const response = await regulatory360ApiWithFilters(source, applno, 'indication_timeline', {
      filters,
      view_type: RESULT_VIEW_TYPES.APPLICATION
    } as any);
    if (response?.status === 200) {
      const value = response?.data?.body;
      return value;
    }
    return {};
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('failed get indication timline data', e);
    return {};
  }
};

export default getIndicationChangeData;
