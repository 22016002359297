const styles: { [key: string]: any } = {
  container: {
    height: '100%',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      bgcolor: '#f2f2f2'
    }
  },
  text: {
    fontSize: 13,
    color: 'gray.800',
    fontWeight: 400,
    margin: 1.5,
    maxWidth: '100%',
    textAlign: 'left',
    display: '-webkit-box !important',
    WebkitLineClamp: '3  !important',
    WebkitBoxOrient: 'vertical  !important'
  },
  submissionText: {
    display: 'flex',
    height: 16,
    padding: 0.5,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 1.25,
    borderRadius: 1,
    backgroundColor: 'gray.100',
    color: 'gray.800',
    fontSize: 12,
    fontWeight: 600,
    ml: 0.25
  },
  paperContainer: {
    maxHeight: 290,
    overflowY: 'auto',
    borderColor: 'transparent',
    borderRadius: '8px',
    p: 2,
    width: 400
  },
  moreButton: {
    color: 'primary.600',
    fontSize: 12,
    fontWeight: 600
  },
  subHeading: {
    fontSize: 13,
    color: 'gray.800',
    fontWeight: 400
  }
};

export default styles;
