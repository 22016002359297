const styles = {
  gridContainer: {
    overflow: 'auto',
    maxHeight: '80%',
    padding: '24px 24px 0px 24px',
    background: 'white',
    border: '1px solid',
    borderColor: 'grey.300',
    '&::-webkit-scrollbar': {
      width: '7px',
      height: '7px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'grey.300',
      borderRadius: '5px'
    }
  },
  horizontalTimelineStyle: {
    cursor: 'grab',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    backgroundColor: 'white.main'
  },
  timeLineCards: {
    pt: '2.5rem',
    position: 'sticky !important',
    left: 0,
    zIndex: 3,
    backgroundColor: 'white.main',
    display: 'flex',
    height: 'fit-content'
  },
  applText: {
    overflow: 'hidden',
    color: 'grey.800',
    textOverflow: 'ellipsis',
    fontSize: 13,
    fontWeight: 600,
    whiteSpace: 'nowrap'
  },
  yearBox: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: 'white.main'
  },
  plusStyle: {
    position: 'relative',
    height: '76px',
    borderColor: 'grey.400',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      background: '#0000'
    },
    '&::before': {
      left: '50%',
      top: '0%',
      bottom: '4px',
      transform: 'translateX(-50%)',
      border: '1px dashed #adadad80'
    },
    '&::after': {
      left: '0%',
      top: '50%',
      right: '4px',
      height: '1px',
      transform: 'translateY(-50%)',
      border: '1px dashed #adadad80'
    }
  },
  badgeContainer: {
    zIndex: 1,
    position: 'relative',
    top: '28%',
    textAlign: 'center',
    pl: '16px',
    pr: '16px'
  },
  badgeStyle: {
    '& .MuiBadge-badge': {
      backgroundColor: '#fff',
      color: 'primary.main',
      border: '1px solid',
      borderColor: 'primary.main',
      height: '18px',
      width: '18px',
      fontSize: '10px',
      fontWeight: 700,
      right: '3px'
    }
  },
  popoverContainer: {
    display: 'flex',
    width: 380,
    padding: 2.5,
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 1.5,
    borderRadius: 3,
    backgroundColor: 'gray.0',
    boxShadow: '2px 4px 8px 0px rgba(0, 0, 0, 0.25)',
    border: '2px solid',
    borderColor: 'gray.300'
  },
  metadataChipContainer: {
    display: 'flex',
    py: 0,
    px: 0.75,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 1.25,
    border: '1px solid',
    width: 'fit-content',
    borderRadius: 1,
    borderColor: 'gray.300',
    height: 20
  },
  indicationText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 400,
    color: 'gray.800'
  },
  indicationHeading: {
    color: 'gray.800',
    fontSize: 14,
    fontWeight: 700
  },
  clickButtonText: {
    color: 'primary.700',
    fontSize: 14,
    fontWeight: 600
  },
  CardContainer: {
    p: 0.5,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    mt: 0,
    position: 'relative',
    display: 'flex',
    width: 200,
    alignItems: 'stretch',
    height: 36,
    mb: 5
  },
  downloadbuttonIcon: { height: 20, width: 20, color: 'gray.700', cursor: 'pointer' },
  buttonIcon: { height: 16, width: 16, color: 'gray.700', cursor: 'pointer' },
  message: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  iconButton: {
    height: 30,
    py: 0,
    px: 1,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'secondary.darkVariant',
      cursor: 'pointer',
      boxShadow: 1,
      transform: 'scale(1.1)'
    }
  },
  indicationChangeIcon: {
    width: 15,
    height: 15,
    color: 'gray.0'
  }
};

export default styles;
