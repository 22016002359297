import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import Metadata from '../../../components/Dialogs/DocumentListing/components/EntityMetadata';
import styles from './styles/IndicationChangeTimelineView.styles';
import { ArrowLeftIcon } from '../../../assets/svgs/Icons';

export interface IndicationPopoverProps {
  selectedEntityId: string;
  // eslint-disable-next-line react/require-default-props
  subEntities?: Array<any>;
  expandedPayload: any;
  handleClose: () => void;
  // eslint-disable-next-line no-unused-vars
  setOpenIndicationDrawer: (open: boolean) => void;
}

const IndicationPopover = ({
  selectedEntityId,
  expandedPayload,
  subEntities = [],
  handleClose,
  setOpenIndicationDrawer
}: IndicationPopoverProps) => {
  const selectedEntity = useMemo(() => {
    return subEntities?.find((entity: any) => entity.id === selectedEntityId);
  }, [selectedEntityId]);

  return (
    <Box width='100%' gap={1.5}>
      <Stack direction='row' alignItems='center'>
        <Box display='flex' alignItems='center'>
          <Tooltip title={selectedEntity?.name}>
            <Typography
              color='gray.900'
              fontWeight={700}
              fontSize={14}
              lineHeight={1.5}
              overflow='hidden'
              mt={0.25}>
              {selectedEntity?.name}
            </Typography>
          </Tooltip>
        </Box>
        {selectedEntity?.metadata && selectedEntity?.metadata?.length > 0 && (
          <>
            <Divider orientation='vertical' sx={{ height: 16, mx: 1.5, borderColor: 'gray.400' }} />
            <Metadata data={selectedEntity?.metadata} />
          </>
        )}
      </Stack>
      <Divider sx={{ borderColor: 'gray.200', my: 1.5 }} />
      <Box sx={styles.metadataChipContainer}>
        <Typography sx={styles.indicationText}>{expandedPayload?.indication}</Typography>
      </Box>
      <Typography mt={1.5} sx={styles.indicationHeading}>
        Indication Added
      </Typography>
      <Box
        sx={{
          maxHeight: 100,
          overflow: 'auto'
        }}>
        <Typography sx={styles.indicationText}>{expandedPayload?.indication_added}</Typography>
      </Box>
      <Divider sx={{ borderColor: 'gray.200', my: 1.5 }} />
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{
          cursor: 'pointer'
        }}
        onClick={() => {
          handleClose();
          setOpenIndicationDrawer(true);
        }}>
        <Typography
          sx={
            styles.clickButtonText
          }>{`View All Indications for ${expandedPayload?.submission}`}</Typography>
        <ArrowLeftIcon sx={{ color: 'primary.700', fontSize: 16, rotate: '180deg' }} />
      </Box>
    </Box>
  );
};

export default React.memo(IndicationPopover);
