import { createSvgIcon } from '@mui/material/utils';

const IndicationChangeIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <g clipPath='url(#clip0_5749_183)'>
      <path
        d='M8.00013 2.02019C8.82919 2.02291 9.64949 2.19003 10.4135 2.51187C11.1776 2.83371 11.8702 3.30389 12.4514 3.89519H10.5001C10.3344 3.89519 10.1754 3.96104 10.0582 4.07825C9.94097 4.19546 9.87513 4.35443 9.87513 4.52019C9.87513 4.68595 9.94097 4.84492 10.0582 4.96213C10.1754 5.07934 10.3344 5.14519 10.5001 5.14519H13.0895C13.3973 5.14503 13.6924 5.02269 13.91 4.80507C14.1276 4.58745 14.25 4.29233 14.2501 3.98457V1.39519C14.2501 1.22943 14.1843 1.07046 14.0671 0.95325C13.9499 0.83604 13.7909 0.770192 13.6251 0.770192C13.4594 0.770192 13.3004 0.83604 13.1832 0.95325C13.066 1.07046 13.0001 1.22943 13.0001 1.39519V2.69394C11.9671 1.7636 10.6955 1.1392 9.32769 0.89066C7.95988 0.642122 6.54989 0.779262 5.25562 1.28672C3.96134 1.79418 2.83389 2.65192 1.99951 3.76389C1.16513 4.87586 0.656778 6.19816 0.531377 7.58269C0.523304 7.66973 0.533449 7.75749 0.561166 7.84039C0.588883 7.92329 0.633564 7.99951 0.692362 8.06418C0.75116 8.12886 0.822786 8.18058 0.902675 8.21605C0.982565 8.25152 1.06897 8.26996 1.15638 8.27019C1.30924 8.27214 1.45734 8.21697 1.57166 8.11547C1.68599 8.01397 1.75832 7.87346 1.7745 7.72144C1.91364 6.16566 2.62949 4.71813 3.78143 3.66322C4.93337 2.6083 6.43814 2.02225 8.00013 2.02019Z'
        fill='currentColor'
      />
      <path
        d='M14.8444 8.27007C14.6916 8.26812 14.5435 8.3233 14.4291 8.42479C14.3148 8.52629 14.2425 8.6668 14.2263 8.81882C14.123 10.0083 13.6806 11.1433 12.9517 12.089C12.2228 13.0346 11.2379 13.7514 10.1139 14.1542C8.98989 14.557 7.77393 14.6289 6.61028 14.3614C5.44663 14.0939 4.38412 13.4982 3.54881 12.6451H5.50006C5.66582 12.6451 5.82479 12.5792 5.942 12.462C6.05921 12.3448 6.12506 12.1858 6.12506 12.0201C6.12506 11.8543 6.05921 11.6953 5.942 11.5781C5.82479 11.4609 5.66582 11.3951 5.50006 11.3951H2.91069C2.75825 11.395 2.60729 11.425 2.46644 11.4833C2.32559 11.5416 2.19761 11.627 2.08982 11.7348C1.98203 11.8426 1.89654 11.9706 1.83824 12.1115C1.77994 12.2523 1.74998 12.4033 1.75006 12.5557V15.1451C1.75006 15.3108 1.81591 15.4698 1.93312 15.587C2.05033 15.7042 2.2093 15.7701 2.37506 15.7701C2.54082 15.7701 2.69979 15.7042 2.817 15.587C2.93421 15.4698 3.00006 15.3108 3.00006 15.1451V13.8463C4.03308 14.7767 5.30469 15.4011 6.6725 15.6496C8.04031 15.8982 9.45029 15.761 10.7446 15.2536C12.0388 14.7461 13.1663 13.8884 14.0007 12.7764C14.8351 11.6644 15.3434 10.3421 15.4688 8.95757C15.4769 8.87054 15.4667 8.78277 15.439 8.69987C15.4113 8.61697 15.3666 8.54076 15.3078 8.47608C15.249 8.4114 15.1774 8.35968 15.0975 8.32421C15.0176 8.28874 14.9312 8.2703 14.8438 8.27007H14.8444Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_5749_183'>
        <rect width='15' height='15' fill='white' transform='translate(0.5 0.77002)' />
      </clipPath>
    </defs>
  </svg>,
  'IndicationChangeIcon'
);
export default IndicationChangeIcon;
