const styles = {
  mainDrawer: {
    '& .MuiDrawer-paper': {
      right: 0,
      width: 700,
      maxWidth: 700,
      height: '100%',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      overflow: 'hidden',
      backgroundColor: 'gray.50'
    }
  },
  drawerHeaderIcon: { fontSize: 16, color: 'white.main' },
  drawerTitle: {
    maxWidth: '45%',
    overflow: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    color: 'white.main',
    fontSize: 20,
    fontWeight: 700
  },
  headingText: {
    color: 'gray.800',
    fontSize: 14,
    lineHeight: '140%'
  },
  accordionContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100% - 180px)',
    position: 'relative',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.400',
      borderRadius: 1,
      opacity: '0.8'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    }
  },
  accordionSummaryTitle: {
    color: 'gray.900',
    fontSize: 16,
    fontWeight: 700,
    ml: 1
  },
  accordionDetails: { m: 0, px: 2, pt: 1.5, pb: 2.5 },
  accordionText: { color: 'gray.700', fontSize: 13, fontWeight: 400 },
  caretDownIcon: { fontSize: 16, color: 'gray.800' },
  submissionText: {
    display: 'flex',
    height: 24,
    padding: '0px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 1.25,
    borderRadius: 1,
    backgroundColor: 'gray.100',
    color: 'gray.800',
    fontSize: 14,
    fontWeight: 700,
    ml: 1
  },
  divider: { mx: 2, borderColor: 'gray.200' },
  diseaseDivider: {
    my: 1,
    mx: 0,
    borderColor: 'gray.200'
  },
  crossIcon: { ml: 'auto', p: 0.75, mr: -0.75 },
  chipContainer: {
    display: 'flex',
    height: 32,
    py: 1,
    px: 2,
    alignItems: 'center',
    gap: 0.75,
    backgroundColor: 'primary.0',
    border: '1px solid',
    borderColor: 'primary.100',
    borderRadius: 2
  },
  metadataText: {
    color: 'gray.800',
    fontSize: 13,
    fontWeight: 600,
    textTransform: 'capitalize'
  }
};

export default styles;
